// src/components/NewsReader/hooks/useActiveFilters.js
import { useMemo } from 'react';
import { TIME_RANGES, PROFILES } from '../constants/filterOptions';

export const useActiveFilters = (filters, defaultFilters) => {
  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (filters.timeRange !== defaultFilters.timeRange) count++;
    if (filters.selectedProfile !== defaultFilters.selectedProfile) count++;
    if (filters.sortOrder !== defaultFilters.sortOrder) count++;
    if (filters.selectedView !== defaultFilters.selectedView) count++;
    return count;
  }, [filters, defaultFilters]);

  const activeFilters = useMemo(() => {
    const activeFilters = [];

    if (filters.timeRange !== defaultFilters.timeRange) {
      const timeRange = TIME_RANGES.find(r => r.value === filters.timeRange);
      activeFilters.push({
        key: 'time',
        label: timeRange?.label,
        onDelete: () => ({ timeRange: defaultFilters.timeRange })
      });
    }

    if (filters.selectedProfile !== defaultFilters.selectedProfile) {
      const profile = PROFILES.find(p => p.value === filters.selectedProfile);
      activeFilters.push({
        key: 'profile',
        label: profile?.label,
        onDelete: () => ({ selectedProfile: defaultFilters.selectedProfile })
      });
    }

    if (filters.selectedView !== defaultFilters.selectedView) {
      activeFilters.push({
        key: 'view',
        label: `View: ${filters.selectedView}`,
        onDelete: () => ({ selectedView: defaultFilters.selectedView })
      });
    }

    return activeFilters;
  }, [filters, defaultFilters]);

  return {
    activeFiltersCount,
    activeFilters: activeFilters
  };
};