import React from 'react';
import { Box, Skeleton, Card } from '@mui/material';


const NewsFeedSkeleton = () => {
  return (
    <div className="bg-gray-900 min-h-screen">

      {/* News items */}
      <div className="space-y-4 p-4">
        {[1, 2, 3, 4].map((item) => (
          <Card key={item} className="p-4 bg-gray-800">
            <div className="flex items-start gap-3">
              <Skeleton className="h-8 w-8 rounded-full bg-gray-700" /> {/* Source icon */}
              <div className="flex-1 space-y-2">
                <Skeleton className="h-4 w-16 bg-gray-700" /> {/* Time ago */}
                <Skeleton className="h-5 w-full bg-gray-700" /> {/* Title line 1 */}
                <Skeleton className="h-5 w-4/5 bg-gray-700" /> {/* Title line 2 */}
                <Skeleton className="h-4 w-32 bg-gray-700" /> {/* Domain */}
                
                {/* Action buttons */}
                <div className="flex gap-4 mt-4">
                  <Skeleton className="h-6 w-6 bg-gray-700" /> {/* Like */}
                  <Skeleton className="h-6 w-6 bg-gray-700" /> {/* Bookmark */}
                  <Skeleton className="h-6 w-6 bg-gray-700" /> {/* Share */}
                  <Skeleton className="h-6 w-6 bg-gray-700" /> {/* More */}
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default NewsFeedSkeleton;