// src/components/NewsReader/Mobile/MobileFilterDrawer.js
import React from 'react';
import {
  Drawer,
  IconButton,
  Box,
  Typography,
  Divider,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { X } from 'lucide-react';

const FilterDrawer = ({
  open,
  onClose,
  filters,
  defaultFilters,
  onFilterChange,
  onResetFilters,
  timeRanges,
  profiles,
  markAsReadOptions,
  onViewChange,
  onSortChange,
  onProfileChange,
  onTimeFilterChange,
  onMarkAsReadChange,
  unreadArticlesCount,
  savedArticlesCount,
}) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: 360,
        },
      }}
    >
      {/* Header */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <Typography variant="h6">Filters</Typography>
        <IconButton edge="end" onClick={onClose} size="small">
          <X size={20} />
        </IconButton>
      </Box>

      {/* Content */}
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <List>
          {/* View Selection */}
          <ListSubheader>View</ListSubheader>
          <ListItem>
            <FormControl fullWidth>
              <Select
                value={filters.selectedView}
                onChange={onViewChange}
                size="small"
              >
                <MenuItem value="unread">Unread ({unreadArticlesCount})</MenuItem>
                <MenuItem value="all">All Articles</MenuItem>
                <MenuItem value="saved">Saved ({savedArticlesCount})</MenuItem>
              </Select>
            </FormControl>
          </ListItem>

          {/* Sort Order */}
          <ListSubheader>Sort Order</ListSubheader>
          <ListItem>
            <FormControl component="fieldset">
              <RadioGroup
                value={filters.sortOrder}
                onChange={onSortChange}
              >
                <FormControlLabel 
                  value="desc" 
                  control={<Radio />} 
                  label="Newest first"
                />
                <FormControlLabel 
                  value="asc" 
                  control={<Radio />} 
                  label="Oldest first"
                />
              </RadioGroup>
            </FormControl>
          </ListItem>

          {/* Time Range */}
          <ListSubheader>Time Range</ListSubheader>
          <ListItem>
            <FormControl component="fieldset">
              <RadioGroup
                value={filters.timeRange}
                onChange={(e) => onTimeFilterChange(e.target.value)}
              >
                {timeRanges.map(range => (
                  <FormControlLabel
                    key={range.value}
                    value={range.value}
                    control={<Radio />}
                    label={range.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </ListItem>

          {/* Profile Selection */}
          <ListSubheader>Profile</ListSubheader>
          <ListItem>
            <FormControl fullWidth>
              <Select
                value={filters.selectedProfile}
                onChange={(e) => onProfileChange(e.target.value)}
                size="small"
              >
                {profiles.map(profile => (
                  <MenuItem
                    key={profile.value}
                    value={profile.value}
                  >
                    {profile.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>

          {/* Mark as Read */}
          <ListSubheader>Mark as Read</ListSubheader>
          <ListItem>
            <FormControl fullWidth>
              <Select
                value=""
                onChange={onMarkAsReadChange}
                displayEmpty
                size="small"
              >
                <MenuItem value="" disabled>Select option</MenuItem>
                {markAsReadOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
        </List>
      </Box>

      {/* Footer */}
      <Box sx={{
        p: 2,
        borderTop: 1,
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Button
          variant="outlined"
          onClick={onResetFilters}
          fullWidth
          sx={{ mr: 1 }}
        >
          Reset Filters
        </Button>
        <Button
          variant="contained"
          onClick={onClose}
          fullWidth
          sx={{ ml: 1 }}
        >
          Apply
        </Button>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;