// src/components/NewsReader/hooks/useSearchFilter.js
import { useState, useCallback } from 'react';

export const useSearchFilter = (initialValue = '', onFilterChange) => {
  const [searchInput, setSearchInput] = useState(initialValue);

  const handleSearchChange = useCallback((event) => {
    const value = event.target.value;
    setSearchInput(value);

    // Si le champ devient vide, mettre à jour les filtres
    if (value === '' && initialValue !== '') {
      onFilterChange?.({ searchTerm: '' });
    }
  }, [initialValue, onFilterChange]);

  const handleSearchClear = useCallback(() => {
    if (searchInput !== '') {
      setSearchInput('');
      onFilterChange?.({ searchTerm: '' });
    }
  }, [searchInput, onFilterChange]);

  const handleSearchSubmit = useCallback(() => {
    if (searchInput !== initialValue) {
      onFilterChange?.({ searchTerm: searchInput });
    }
  }, [searchInput, initialValue, onFilterChange]);

  return {
    searchInput,
    setSearchInput,
    handleSearchChange,
    handleSearchClear,
    handleSearchSubmit
  };
};