// src/components/NewsReader/NewsReaderToolbar.js
import React from 'react';
import {
  AppBar,
  Toolbar,
  TextField,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Tooltip,
  Menu,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  Box,
  Badge,
  Stack,
  alpha,
  Zoom,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Search,
  X,
  SortDesc,
  Calendar,
  User,
  RefreshCw,
} from 'lucide-react';
import { useBaseNewsReaderToolbar } from './base/BaseNewsReaderToolbar';
import { DEFAULT_FILTERS } from './constants/filterOptions';

// Composant pour le badge des filtres actifs
const FilterBadge = ({ active, children, color = "primary" }) => (
  <Badge
    variant="dot"
    color={color}
    invisible={!active}
  >
    {children}
  </Badge>
);

const NewsReaderToolbar = React.memo(({
  filters = DEFAULT_FILTERS,
  defaultFilters = DEFAULT_FILTERS,
  onFilterChange,
  onResetFilters,
  onMarkAsRead,
  isMobile,
}) => {
  const theme = useTheme();
  
  const {
    // États
    searchInput,
    markAsReadValue,
    sortAnchorEl,
    profileAnchorEl,
    timeAnchorEl,
    activeFiltersCount,
    unreadArticlesCount,
    savedArticlesCount,

    // Setters
    setSortAnchorEl,
    setProfileAnchorEl,
    setTimeAnchorEl,

    // Handlers
    handleSearchChange,
    handleSearchClear,
    handleSearchSubmit,
    handleViewChange,
    handleSortChange,
    handleProfileChange,
    handleTimeFilterChange,
    handleMarkAsReadChange,
    handleResetFilters,

    // Constants
    TIME_RANGES,
    PROFILES,
    MARK_AS_READ_OPTIONS
  } = useBaseNewsReaderToolbar({
    filters,
    defaultFilters,
    onFilterChange,
    onResetFilters,
    onMarkAsRead,
    isMobile: false
  });

  // Mémoisation des tooltips
  const tooltips = React.useMemo(() => ({
    timeFilter: filters?.timeRange && filters.timeRange !== '0'
      ? `Time Range: ${TIME_RANGES.find(r => r.value === filters.timeRange)?.label}`
      : 'Time filter',
    sort: `Sort: ${filters?.sortOrder === 'desc' ? 'Newest first' : 'Oldest first'}`,
    profile: filters?.selectedProfile
      ? `Profile: ${PROFILES.find(p => p.value === filters.selectedProfile)?.label}`
      : 'Change Profile'
  }), [filters?.timeRange, filters?.sortOrder, filters?.selectedProfile]);

  // Vérifier si des filtres sont actifs pour l'animation de réinitialisation
  const hasActiveFilters = React.useMemo(() => {
    return (
      filters.searchTerm !== defaultFilters.searchTerm ||
      filters.timeRange !== defaultFilters.timeRange ||
      filters.sortOrder !== defaultFilters.sortOrder ||
      filters.selectedProfile !== defaultFilters.selectedProfile ||
      filters.selectedView !== defaultFilters.selectedView
    );
  }, [filters, defaultFilters]);

  // Version mobile du toolbar
  if (isMobile) {
    return (
      <AppBar
        position="sticky"
        color="default"
        elevation={0}
        sx={{
          backgroundColor: alpha(theme.palette.background.paper, 0.95),
          backdropFilter: 'blur(12px)',
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Toolbar
          sx={{
            flexDirection: 'column',
            gap: 1,
            p: 1,
          }}
        >
          {/* Première ligne : Vue et Recherche */}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: 1,
              mb: 0.5,
            }}
          >
            {/* View Selection avec design amélioré */}
            <Select
              value={filters.selectedView}
              onChange={handleViewChange}
              size="small"
              sx={{
                minWidth: 110,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                },
                '& .MuiSelect-select': {
                  py: 1,
                },
              }}
            >
              <MenuItem value="unread">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Badge color="primary" badgeContent={unreadArticlesCount} max={99}>
                    <Typography variant="body2">Unread</Typography>
                  </Badge>
                </Stack>
              </MenuItem>
              <MenuItem value="all">All Articles</MenuItem>
              <MenuItem value="saved">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Badge color="secondary" badgeContent={savedArticlesCount} max={99}>
                    <Typography variant="body2">Saved</Typography>
                  </Badge>
                </Stack>
              </MenuItem>
            </Select>

            {/* Champ de recherche optimisé pour mobile */}
            <TextField
              placeholder="Search..."
              value={searchInput}
              onChange={handleSearchChange}
              onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit()}
              size="small"
              sx={{
                flexGrow: 1,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  backgroundColor: alpha(theme.palette.background.default, 0.6),
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.background.default, 0.8),
                  },
                  '&.Mui-focused': {
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search className="h-4 w-4" style={{ color: theme.palette.text.secondary }} />
                  </InputAdornment>
                ),
                endAdornment: searchInput && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleSearchClear}
                      size="small"
                      sx={{
                        color: theme.palette.text.secondary,
                        p: 0.5,
                        '&:hover': {
                          color: theme.palette.error.main,
                        },
                      }}
                    >
                      <X className="h-4 w-4" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Deuxième ligne : Filtres et Actions */}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* Boutons de filtres avec badges */}
            <Stack
              direction="row"
              spacing={0.5}
              sx={{
                '& .MuiIconButton-root': {
                  borderRadius: '8px',
                  transition: 'all 0.2s ease',
                  p: 1,
                  backgroundColor: theme.palette.background.default,
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    transform: 'translateY(-1px)',
                  },
                  '&:active': {
                    transform: 'translateY(0)',
                  }
                }
              }}
            >
              {/* Time Filter */}
              <IconButton
                onClick={(e) => setTimeAnchorEl(e.currentTarget)}
                sx={{
                  backgroundColor: filters.timeRange !== '0' ? 
                    alpha(theme.palette.primary.main, 0.1) : undefined,
                }}
              >
                <FilterBadge active={filters.timeRange !== '0'}>
                  <Calendar className="h-5 w-5" />
                </FilterBadge>
              </IconButton>

              {/* Sort Order avec animation de rotation */}
              <IconButton
                onClick={(e) => setSortAnchorEl(e.currentTarget)}
                sx={{
                  transform: filters.sortOrder === 'asc' ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.3s ease',
                }}
              >
                <SortDesc className="h-5 w-5" />
              </IconButton>

              {/* Profile Filter */}
              <IconButton
                onClick={(e) => setProfileAnchorEl(e.currentTarget)}
                sx={{
                  backgroundColor: filters.selectedProfile !== '0' ? 
                    alpha(theme.palette.primary.main, 0.1) : undefined,
                }}
              >
                <FilterBadge active={filters.selectedProfile !== '0'}>
                  <User className="h-5 w-5" />
                </FilterBadge>
              </IconButton>

              {/* Reset Filters avec animation */}
              <Zoom in={hasActiveFilters}>
                <IconButton
                  onClick={handleResetFilters}
                  sx={{
                    transition: 'all 0.3s ease !important',
                    color: theme.palette.primary.main,
                    '&:hover': {
                      transform: 'rotate(-180deg) !important',
                    },
                  }}
                >
                  <RefreshCw className="h-5 w-5" />
                </IconButton>
              </Zoom>
            </Stack>

            {/* Mark as Read optimisé pour mobile */}
            <Select
              value={markAsReadValue}
              onChange={handleMarkAsReadChange}
              displayEmpty
              size="small"
              sx={{
                minWidth: 'auto',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
              }}
            >
              <MenuItem value="" disabled>Mark as read</MenuItem>
              {MARK_AS_READ_OPTIONS.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={0}
      sx={{
        backgroundColor: alpha(theme.palette.background.paper, 0.95),
        backdropFilter: 'blur(12px)',
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Toolbar 
        sx={{
          gap: 1.5,
          px: { xs: 1, sm: 2 },
          flexWrap: 'wrap',
          minHeight: { xs: '56px', sm: '64px' },
        }}
      >
        {/* View Selection */}
        <Select
          value={filters.selectedView}
          onChange={handleViewChange}
          size="small"
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="unread">Unread ({unreadArticlesCount})</MenuItem>
          <MenuItem value="all">All Articles</MenuItem>
          <MenuItem value="saved">Saved ({savedArticlesCount})</MenuItem>
        </Select>

        {/* Search Field avec design amélioré */}
        <TextField
          placeholder="Search articles..."
          value={searchInput}
          onChange={handleSearchChange}
          onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit()}
          size="small"
          sx={{
            flexGrow: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              backgroundColor: alpha(theme.palette.background.default, 0.6),
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: alpha(theme.palette.background.default, 0.8),
              },
              '&.Mui-focused': {
                backgroundColor: theme.palette.background.paper,
                boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
              }
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search 
                  className="h-4 w-4" 
                  style={{ color: theme.palette.text.secondary }}
                />
              </InputAdornment>
            ),
            endAdornment: searchInput && (
              <InputAdornment position="end">
                <IconButton 
                  edge="end" 
                  onClick={handleSearchClear}
                  size="small"
                  sx={{
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      color: theme.palette.error.main,
                      transform: 'scale(1.1)',
                    },
                    transition: 'all 0.2s ease',
                  }}
                >
                  <X className="h-4 w-4" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Mark as Read Selection */}
        <Select
          value={markAsReadValue}
          onChange={handleMarkAsReadChange}
          displayEmpty
          size="small"
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="" disabled>Mark as read</MenuItem>
          {MARK_AS_READ_OPTIONS.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>

        {/* Action Buttons avec design amélioré */}
        <Stack 
          direction="row" 
          spacing={0.5}
          sx={{
            '& .MuiIconButton-root': {
              borderRadius: '10px',
              transition: 'all 0.2s ease',
              p: 1,
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                transform: 'translateY(-1px)',
              },
              '&:active': {
                transform: 'translateY(0px)',
              }
            }
          }}
        >
          {/* Time Filter Button */}
          <Tooltip title={tooltips.timeFilter}>
            <IconButton 
              onClick={(e) => setTimeAnchorEl(e.currentTarget)}
              sx={{
                backgroundColor: filters.timeRange !== '0' ? 
                  alpha(theme.palette.primary.main, 0.1) : 'transparent',
              }}
            >
              <FilterBadge 
                active={filters.timeRange !== '0'}
                color="primary"
              >
                <Calendar className="h-5 w-5" />
              </FilterBadge>
            </IconButton>
          </Tooltip>

          {/* Sort Button avec animation */}
          <Tooltip title={tooltips.sort}>
            <IconButton 
              onClick={(e) => setSortAnchorEl(e.currentTarget)}
              sx={{
                transform: filters.sortOrder === 'asc' ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.3s ease',
              }}
            >
              <SortDesc className="h-5 w-5" />
            </IconButton>
          </Tooltip>

          {/* Profile Button */}
          <Tooltip title={tooltips.profile}>
            <IconButton 
              onClick={(e) => setProfileAnchorEl(e.currentTarget)}
              sx={{
                backgroundColor: filters.selectedProfile !== '0' ? 
                  alpha(theme.palette.primary.main, 0.1) : 'transparent',
              }}
            >
              <FilterBadge 
                active={filters.selectedProfile !== '0'}
                color="primary"
              >
                <User className="h-5 w-5" />
              </FilterBadge>
            </IconButton>
          </Tooltip>

          {/* Reset Button avec animation améliorée */}
          <Zoom in={hasActiveFilters}>
            <Tooltip title="Reset all filters">
              <IconButton
                onClick={handleResetFilters}
                sx={{
                  transition: 'all 0.3s ease !important',
                  color: theme.palette.primary.main,
                  '&:hover': {
                    transform: 'rotate(-180deg) !important',
                    backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
                  },
                }}
              >
                <RefreshCw className="h-5 w-5" />
              </IconButton>
            </Tooltip>
          </Zoom>
        </Stack>

        {/* Menus avec style amélioré */}
        <Menu
          anchorEl={timeAnchorEl}
          open={Boolean(timeAnchorEl)}
          onClose={() => setTimeAnchorEl(null)}
          PaperProps={{
            sx: {
              mt: 1.5,
              borderRadius: '12px',
              boxShadow: theme.shadows[3],
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Box sx={{ p: 2, minWidth: 200 }}>
            <FormControl>
              <FormLabel>Time Range</FormLabel>
              <RadioGroup
                value={filters.timeRange || '0'}
                onChange={(e) => handleTimeFilterChange(e.target.value)}
              >
                {TIME_RANGES.map(range => (
                  <FormControlLabel
                    key={range.value}
                    value={range.value}
                    control={<Radio />}
                    label={range.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Menu>

        {/* Sort Menu */}
        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={() => setSortAnchorEl(null)}
        >
          <Box sx={{ p: 2, minWidth: 200 }}>
            <FormControl>
              <FormLabel>Sort Order</FormLabel>
              <RadioGroup
                value={filters.sortOrder}
                onChange={handleSortChange}
              >
                <FormControlLabel value="desc" control={<Radio />} label="Newest first" />
                <FormControlLabel value="asc" control={<Radio />} label="Oldest first" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Menu>

        {/* Profile Menu */}
        <Menu
          anchorEl={profileAnchorEl}
          open={Boolean(profileAnchorEl)}
          onClose={() => setProfileAnchorEl(null)}
        >
          {PROFILES.map(profile => (
            <MenuItem
              key={profile.value}
              onClick={() => handleProfileChange(profile.value)}
              selected={profile.value === filters.selectedProfile}
            >
              {profile.label}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
});

NewsReaderToolbar.displayName = 'NewsReaderToolbar';

export default NewsReaderToolbar;