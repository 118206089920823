// src/components/NewsReader/constants/filterOptions.js
export const TIME_RANGES = [
    { value: '1', label: 'Today' },
    { value: '2', label: 'Yesterday' },
    { value: '8', label: 'Last 7 days' },
    { value: '31', label: 'Last 30 days' },
    { value: '0', label: 'All Time' },
  ];
  
  export const PROFILES = [
    { value: '0', label: 'All Profiles' },
    { value: '1', label: 'CISO' },
    { value: '2', label: 'Cybersecurity Expert' },
    { value: '3', label: 'Incident Response' },
    { value: '4', label: 'Security Analyst' },
  ];
  
  export const MARK_AS_READ_OPTIONS = [
    { value: 1, label: 'Older than 1 day' },
    { value: 7, label: 'Older than 7 days' },
    { value: 14, label: 'Older than 14 days' },
    { value: 30, label: 'Older than 30 days' },
    { value: 'all', label: 'All Articles' },
  ];
  
  export const DEFAULT_FILTERS = {
    sortOrder: 'desc',
    searchTerm: '',
    timeRange: '0',
    selectedView: 'unread',
    selectedProfile: '0',
    selectedTags: [],
    selectedImportance: 'All',
    language: 'en'
  };