// src/components/NewsReader/Mobile/MobileNewsReaderToolbar.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  Chip,
  Badge,
  Slide,
  Fade,
  Fab,
  useTheme,
} from '@mui/material';
import { Search, Filter, X, ArrowUp } from 'lucide-react';
import FilterDrawer from './MobileFilterDrawer';
import { useBaseNewsReaderToolbar } from '../base/BaseNewsReaderToolbar';
import { DEFAULT_FILTERS } from '../constants/filterOptions';

const MobileNewsReaderToolbar = ({
  filters = DEFAULT_FILTERS,
  defaultFilters = DEFAULT_FILTERS,
  onFilterChange,
  onResetFilters,
  onMarkAsRead,
}) => {
  const theme = useTheme();
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [showSearchFab, setShowSearchFab] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const {
    // États
    searchInput,
    activeFiltersCount,
    activeFilters,

    // Handlers
    handleSearchChange,
    handleSearchClear,
    handleSearchSubmit,
    handleViewChange,
    handleSortChange,
    handleProfileChange,
    handleTimeFilterChange,
    handleMarkAsReadChange,
    handleResetFilters,

    // Constants
    TIME_RANGES,
    PROFILES,
    MARK_AS_READ_OPTIONS,

    // Autres états nécessaires pour le drawer
    unreadArticlesCount,
    savedArticlesCount,
  } = useBaseNewsReaderToolbar({
    filters,
    defaultFilters,
    onFilterChange,
    onResetFilters,
    onMarkAsRead,
    isMobile: true
  });

  // Gérer le comportement de scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowSearchBar(scrollPosition < 100);
      setShowSearchFab(scrollPosition >= 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handler pour scroll to top
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setShowSearchBar(true);
  };

  // Handler pour ouvrir/fermer le drawer des filtres
  const handleFilterDrawerToggle = () => {
    setFilterDrawerOpen(!filterDrawerOpen);
  };

  return (
    <>
      <Slide direction="down" in={showSearchBar} mountOnEnter unmountOnExit>
        <Box sx={{
          position: 'sticky',
          top: 56,
          zIndex: 1099,
          backgroundColor: 'background.paper',
          boxShadow: theme.shadows[1],
          mt: -6,
          width: '100%'
        }}>
          {/* Barre de recherche et filtres */}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            px: 1,
            mx: 0,
          }}>
            <Box sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              backgroundColor: theme.palette.action.hover,
              borderRadius: 1,
              px: 1,
              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
            }}>
              <Search size={18} color={theme.palette.text.secondary} />
              <InputBase
                placeholder="Search articles..."
                value={searchInput}
                onChange={handleSearchChange}
                onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit()}
                sx={{ ml: 1, flex: 1, fontSize: '1rem' }}
              />
              {searchInput && (
                <IconButton size="small" onClick={handleSearchClear}>
                  <X size={16} />
                </IconButton>
              )}
            </Box>
            <Badge
              badgeContent={activeFiltersCount}
              color="primary"
            >
              <IconButton
                size="small"
                onClick={handleFilterDrawerToggle}
                sx={{
                  ml: 1,
                  backgroundColor: activeFiltersCount > 0 ? 'primary.main' : 'transparent',
                  color: activeFiltersCount > 0 ? 'white' : 'text.primary',
                }}
              >
                <Filter size={18} />
              </IconButton>
            </Badge>
          </Box>

          {/* Active Filters */}
          {activeFiltersCount > 0 && (
            <Box sx={{
              display: 'flex',
              gap: 1,
              p: 1,
              overflowX: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              scrollbarWidth: 'none'
            }}>
              {activeFilters.map((filter) => (
                <Chip
                  key={filter.key}
                  label={filter.label}
                  size="small"
                  onDelete={() => onFilterChange(filter.onDelete())}
                />
              ))}
            </Box>
          )}
        </Box>
      </Slide>

      {/* Floating Action Buttons */}
      <Fade in={showSearchFab}>
        <Box sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1200,
          display: 'flex',
          gap: 1
        }}>
          {activeFiltersCount > 0 && (
            <Fab
              size="small"
              color="primary"
              onClick={handleFilterDrawerToggle}
            >
              <Badge badgeContent={activeFiltersCount} color="error">
                <Filter size={20} />
              </Badge>
            </Fab>
          )}
          <Fab
            color="primary"
            onClick={handleScrollToTop}
          >
            <Search size={20} />
          </Fab>
        </Box>
      </Fade>

      {/* Filter Drawer */}
      <FilterDrawer
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        filters={filters}
        defaultFilters={defaultFilters}
        onFilterChange={onFilterChange}
        onResetFilters={handleResetFilters}
        timeRanges={TIME_RANGES}
        profiles={PROFILES}
        markAsReadOptions={MARK_AS_READ_OPTIONS}
        onViewChange={handleViewChange}
        onSortChange={handleSortChange}
        onProfileChange={handleProfileChange}
        onTimeFilterChange={handleTimeFilterChange}
        onMarkAsReadChange={handleMarkAsReadChange}
        unreadArticlesCount={unreadArticlesCount}
        savedArticlesCount={savedArticlesCount}
      />
    </>
  );
};

MobileNewsReaderToolbar.displayName = 'MobileNewsReaderToolbar';

export default MobileNewsReaderToolbar;