import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Grid2,
  Divider,
  Tab,
  Tabs,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  useTheme,
  styled
} from '@mui/material';
import ApiService from '../services/api';
import BillingSection from '../components/settings/BillingSection';
import ProfileSection from '../components/settings/ProfileSection';
import AccountSection from '../components/settings/AccountSection';
import { useSession } from '../contexts/SessionContext';
import { useSnackbar } from '../contexts/SnackbarContext';


const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTab-root': {
    textTransform: 'none',
    minWidth: 100,
    fontSize: '0.875rem',
    padding: '12px 16px',
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiTabs-indicator': {
    height: 3,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius * 2,
  marginTop: theme.spacing(3),
}));

const TabPanel = ({ children, value, index }) => (
  <Box role="tabpanel" hidden={value !== index} sx={{ mt: 3 }}>
    {value === index && children}
  </Box>
);


// Map des onglets et leurs valeurs correspondantes
const TAB_MAP = {
  profile: 0,
  subscription: 1,
  account: 2
};

// Map inverse pour obtenir le nom de l'onglet à partir de l'index
const INVERSE_TAB_MAP = {
  0: 'profile',
  1: 'subscription',
  2: 'account'
};


const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const api = ApiService();
  const { session, updateDefaultLanguage } = useSession();  // Custom hook to get the session data
  const { showSnackbar } = useSnackbar(); // Custom hook to show a snackbar notification
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle form submission
  const [formData, setFormData] = useState({
    profile_image_url: "",
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    default_profile: "0",
    default_language: "en",
    country: "",
  });


  // Effet pour synchroniser l'onglet avec l'URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab && TAB_MAP.hasOwnProperty(tab)) {
      setCurrentTab(TAB_MAP[tab]);
    }
  }, [location]);

  // Gestionnaire de changement d'onglet
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    const tabName = INVERSE_TAB_MAP[newValue];
    navigate(`/settings?tab=${tabName}`, { replace: true });
  };


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get('/users/profile');
        setFormData(prevData => ({
          ...prevData,
          ...response.data
        }));

      } catch (error) {
        showSnackbar('Error fetching user settings', 'error');
      }
    };

    fetchUserData();
  }, []);

  const handleCountryChange = (countryCode) => {
    setFormData({ ...formData, country: countryCode });
  };


  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'default_language') {
      updateDefaultLanguage (value);
    }

  }, []);


  const handleImageUpdate = (newUrl) => {
    setFormData(prev => ({
      ...prev,
      profile_image_url: newUrl
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifier si déjà en cours de soumission
    if (isSubmitting) {
      return;
    }
    
    try {
      setIsSubmitting(true); // Début de la soumission
      await api.put('/users/profile', formData);
      showSnackbar('Profile updated successfully', 'success');
    } catch (error) {
      showSnackbar('Error updating profile', 'error');
      console.error('Error updating profile:', error);
      // Add error notification here if needed
    } finally {
      setIsSubmitting(false); // Fin de la soumission
    }
  };

  return (
    <Box sx={{ pb: 8 }}>
      <Container maxWidth="md">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
            Settings
          </Typography>
          <StyledTabs value={currentTab} onChange={handleTabChange}>
            <Tab label="Profile" />
            <Tab label="Subscription" />
            <Tab label="Account" />
          </StyledTabs>
        </Box>

        <TabPanel value={currentTab} index={0}>
          <form onSubmit={handleSubmit}>
            <ProfileSection
              formData={formData}
              onSubmit={handleSubmit}
              onInputChange={handleInputChange}
              onCountryChange={handleCountryChange}
              onImageUpdate={handleImageUpdate}
              isSubmitting={isSubmitting}
            />
          </form>
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <BillingSection />
        </TabPanel>

        <TabPanel value={currentTab} index={2}>
          <AccountSection />
        </TabPanel>
      </Container>
    </Box>
  );
};

export default Settings;