import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { Box, IconButton } from '@mui/material';
import { Check } from 'lucide-react';

const MobileSwipeableArticle = ({ children, onMarkAsRead, isRead }) => {
  const [offset, setOffset] = React.useState(0);
  const [isDragging, setIsDragging] = React.useState(false);

  const triggerHapticFeedback = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(50);
    }
  };

  const handlers = useSwipeable({
    onSwiping: (event) => {
      if (event.dir === 'Right' && !isRead) {
        const newOffset = Math.min(100, event.deltaX);
        setOffset(newOffset);
      }
    },
    onSwipeEnd: (event) => {
      if (offset > 50 && !isRead) {
        triggerHapticFeedback();
        onMarkAsRead();
        setOffset(0);
      } else {
        setOffset(0);
      }
      setIsDragging(false);
    },
    onSwipeStart: () => {
      setIsDragging(true);
    },
    trackMouse: true,
    trackTouch: true,
  });

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          width: '100%',
          backgroundColor: isRead ? 'action.disabledBackground' : 'success.light',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: 2,
          opacity: offset / 100,
        }}
      >
        <IconButton sx={{ color: 'white' }}>
          <Check />
        </IconButton>
      </Box>

      <Box
        {...handlers}
        sx={{
          transform: `translateX(${offset}px)`,
          transition: isDragging ? 'none' : 'transform 0.2s ease-out',
          position: 'relative',
          backgroundColor: 'background.paper',
          touchAction: 'pan-y',
          pointerEvents: isRead ? 'none' : 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MobileSwipeableArticle; 