import React, { useCallback } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import NewsReader from '../components/NewsReader';
import NewsfeedToolbar from '../components/NewsReader/NewsReaderToolbar';
import MobileNewsReaderToolbar from '../components/NewsReader/Mobile/MobileNewsReaderToolbar';
import { useSession } from '../contexts/SessionContext';
import { useAppSettings } from '../contexts/AppSettingsContext';
import ApiService from '../services/api';

const NewsContainer = () => {
  const { session } = useSession();
  const { unreadArticlesCount, setUnreadArticlesCount } = useAppSettings();
  const { savedArticlesCount, setSavedArticlesCount } = useAppSettings();
  const api = ApiService();
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Définir les filtres par défaut
  const defaultFilters = {
    searchTerm: '',
    selectedTags: [],
    selectedImportance: 'All',
    sortOrder: 'desc',
    timeRange: '0',
    selectedView: 'unread',
    selectedProfile: '0', //à changer plus tard avec la valeur définie par l'utilisateur
    language: 'en' //à changer plus tard avec la valeur définie par l'utilisateur
  };

  // Initialiser les filtres avec les valeurs par défaut
  const [filters, setFilters] = React.useState(defaultFilters);

  // Gestionnaire pour les mises à jour de filtres
  const handleFiltersChange = useCallback((newFilters) => {
    if (typeof newFilters === 'object') {
      setFilters(prev => ({ ...prev, ...newFilters }));
    } else if (typeof newFilters === 'function') {
      setFilters(newFilters);
    }
  }, []);

  // Gestionnaires spécifiques pour la version mobile
  const handleSearchChange = useCallback((value) => {
    setFilters(prev => ({ ...prev, searchTerm: value }));
  }, []);

  const handleTagToggle = useCallback((tag) => {
    setFilters(prev => ({
      ...prev,
      selectedTags: prev.selectedTags.includes(tag)
        ? prev.selectedTags.filter(t => t !== tag)
        : [...prev.selectedTags, tag]
    }));
  }, []);

  const handleImportanceChange = useCallback((importance) => {
    setFilters(prev => ({ ...prev, selectedImportance: importance }));
  }, []);

  const handleClearFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  // Mock values - à remplacer par vos vraies données
  const unreadCount = 0;
  const savedCount = 0;
  // Fonction pour marquer les articles comme lus
  const handleMarkAsRead = useCallback(async (value) => {
    try {
      if (value === 'all') {
        await api.put('/articles/read_all');
      } else {
        await api.put(`/articles/read_after_period/${value}`);
      }
      // Mettre à jour le compteur d'articles non lus
      setUnreadArticlesCount(0);
      return true;
    } catch (error) {
      console.error('Error marking articles as read:', error);
      throw error;
    }
  }, [api, setUnreadArticlesCount]);

  return (
    <Box sx={{ 
      width: '100%', 
      height: '100%',
      mt: 0,
      pt: 0,
      px: {
        xs: 1, // Padding horizontal de 8px sur mobile
        sm: 2  // Padding horizontal de 16px sur tablet et plus
      },
      mx: 'auto',
      maxWidth: '100%'
       }}>
      {isMobile ? (
        <MobileNewsReaderToolbar
          filters={filters}
          defaultFilters={defaultFilters}
          onFilterChange={handleFiltersChange}
          onResetFilters={handleClearFilters}
          onMarkAsRead={handleMarkAsRead}
          //searchTerm={filters.searchTerm}
          //onSearchChange={handleSearchChange}
/*           selectedTags={filters.selectedTags}
          onTagToggle={handleTagToggle}
          availableTags={[
            'Vulnerability',
            'Malware',
            'Data Breach',
            'Ransomware',
            'APT',
            'Zero-day',
            'Phishing',
            'Threat Intel',
            'Incident'
          ]} */
          //selectedImportance={filters.selectedImportance}
          //onImportanceChange={handleImportanceChange}
          //onClearFilters={handleClearFilters}
        />
      ) : (
        <NewsfeedToolbar
          filters={filters}
          defaultFilters={defaultFilters}
          onFilterChange={handleFiltersChange}
          onResetFilters={handleClearFilters}
          unreadCount={unreadCount}
          savedCount={savedCount}
          onMarkAsRead={handleMarkAsRead}
        />
      )}
      
      <NewsReader 
        filters={filters}
        onResetFilters={handleClearFilters}
      />
    </Box>
  );
};

export default NewsContainer; 