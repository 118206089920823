// src/components/NewsReader/base/BaseNewsReaderToolbar.js
import { useState, useCallback } from 'react';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { useAppSettings } from '../../../contexts/AppSettingsContext';
 
import { useSearchFilter } from '../hooks/useSearchFilter';
import { useActiveFilters } from '../hooks/useActiveFilters';
import { DEFAULT_FILTERS, TIME_RANGES, PROFILES, MARK_AS_READ_OPTIONS } from '../constants/filterOptions';

export const useBaseNewsReaderToolbar = ({
  filters = DEFAULT_FILTERS,
  defaultFilters = DEFAULT_FILTERS,
  onFilterChange,
  onResetFilters,
  onMarkAsRead,
  isMobile
}) => {
  const { showSnackbar } = useSnackbar();
  const { savedArticlesCount, unreadArticlesCount } = useAppSettings();

  // États locaux pour les menus
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [timeAnchorEl, setTimeAnchorEl] = useState(null);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [markAsReadValue, setMarkAsReadValue] = useState('');

  // Utiliser les hooks personnalisés
  const {
    searchInput,
    handleSearchChange,
    handleSearchClear,
    handleSearchSubmit
  } = useSearchFilter(filters?.searchTerm, onFilterChange);

  const { activeFiltersCount, activeFilters } = useActiveFilters(filters, defaultFilters);

  // Handlers communs
  const handleViewChange = useCallback((event) => {
    const newView = event.target.value;
    onFilterChange?.({ selectedView: newView });
    showSnackbar(`View changed to ${newView} articles`, 'success');
  }, [onFilterChange, showSnackbar]);

  const handleSortChange = useCallback((event) => {
    onFilterChange?.({ sortOrder: event.target.value });
    setSortAnchorEl(null);
  }, [onFilterChange]);

  const handleProfileChange = useCallback((profileValue) => {
    const profile = PROFILES.find(p => p.value === profileValue);
    onFilterChange?.({ selectedProfile: profile.value });
    showSnackbar(`Profile changed to ${profile.label}`, 'success');
    setProfileAnchorEl(null);
  }, [onFilterChange, showSnackbar]);

  const handleTimeFilterChange = useCallback((value) => {
    onFilterChange?.({ timeRange: value });
    setTimeAnchorEl(null);
  }, [onFilterChange]);

  const handleMarkAsReadChange = useCallback(async (event) => {
    const value = event.target.value;
    setMarkAsReadValue(value);

    showSnackbar('Marking articles as read...', 'info');

    try {
      await onMarkAsRead(value);
      showSnackbar(
        `Articles ${value === 'all' ? 'all' : `older than ${value} days`} marked as read`,
        'success'
      );
    } catch (error) {
      console.error('Error marking articles as read:', error);
      showSnackbar('Error marking articles as read', 'error');
    }

    setMarkAsReadValue('');
  }, [onMarkAsRead, showSnackbar]);

  const handleResetFilters = useCallback(() => {
    onResetFilters?.();
    showSnackbar('All filters have been reset', 'success');
  }, [onResetFilters, showSnackbar]);

  return {
    // États
    searchInput,
    markAsReadValue,
    sortAnchorEl,
    profileAnchorEl,
    timeAnchorEl,
    filterDrawerOpen,
    activeFiltersCount,
    activeFilters,
    savedArticlesCount,
    unreadArticlesCount,

    // Setters
    setSortAnchorEl,
    setProfileAnchorEl,
    setTimeAnchorEl,
    setFilterDrawerOpen,

    // Handlers
    handleSearchChange,
    handleSearchClear,
    handleSearchSubmit,
    handleViewChange,
    handleSortChange,
    handleProfileChange,
    handleTimeFilterChange,
    handleMarkAsReadChange,
    handleResetFilters,

    // Constants
    TIME_RANGES,
    PROFILES,
    MARK_AS_READ_OPTIONS
  };
};
