// MobileArticleComponent.jsx
import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Collapse,
  CardActionArea,
  useTheme,
  Chip,
} from '@mui/material';
import { Bookmark, ThumbsUp, Share2, ExternalLink, dot } from 'lucide-react';
import { getImportance, getImportanceColor, getImportanceLetter } from '../../variables/importanceOptions';
import profileIcons from '../../variables/profileIcons';
import MobileSwipeableArticle from '../NewsReader/Mobile/MobileSwipeableArticle';

const MobileArticleComponent = ({
  article,
  onReadLaterToggle,
  onLikeToggle,
  isReadLater,
  isLiked,
  cardColor,
  sx,
  onMarkAsRead
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));

    if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    }
    return date.toLocaleDateString();
  };

  // Extract domain from URL
  const getDomain = (url) => {
    try {
      const domain = new URL(url).hostname;
      return domain.replace(/^www\./, '');
    } catch {
      return url;
    }
  };

  const handleMarkAsRead = () => {
    if (!article.read) {
      onMarkAsRead(article.id);
    }
  };

  return (
    <MobileSwipeableArticle 
      onMarkAsRead={handleMarkAsRead}
      isRead={article.read}
    >
      <Card
        sx={{
          mb: 1,
          width: '100%',
          borderRadius: '12px',
          backgroundColor: theme.palette.background.paper,
          boxShadow: expanded ? theme.shadows[4] : 'none',
          transition: 'all 0.3s ease',
          position: 'relative',
          overflow: 'visible',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: theme.shadows[4],
          }
        }}
      >
        {/* Score indicator */}
        <Box
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: cardColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            zIndex: 2,
          }}
        >
          {getImportanceLetter(article.score)}
        </Box>

        <CardActionArea
          onClick={() => setExpanded(!expanded)}
          sx={{
            p: 2,
            borderRadius: '12px',
            transition: 'background-color 0.2s',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            }
          }}
        >
          {/* Article metadata with improved layout */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
              }}
            >
              {article.source}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.text.secondary,
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              {formatDate(article.published_at)} • {getDomain(article.url)}
            </Typography>
          </Box>

          {/* Title with improved typography */}
          <Typography
            variant="h6"
            sx={{
              fontSize: '1.1rem',
              fontWeight: 600,
              lineHeight: 1.4,
              mb: 2,
              color: theme.palette.text.primary,
            }}
          >
            {article.title}
          </Typography>

          {/* Profile chips with animation */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {article.profiles.map((profile) => {
              const IconComponent = profileIcons[profile.name];
              return (
                <Chip
                  key={profile.id}
                  icon={IconComponent && <IconComponent size={14} />}
                  label={profile.name}
                  sx={{
                    borderRadius: '8px',
                    transition: 'all 0.2s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    }
                  }}
                  size="small"
                  variant="outlined"
                />
              );
            })}
          </Box>

          {/* Action buttons with animations */}
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              mt: 2,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton
              size="small"
              onClick={onLikeToggle}
              sx={{
                color: isLiked ? 'primary.main' : 'text.secondary',
                p: 0.5
              }}
            >
              <ThumbsUp size={18} />
            </IconButton>
            <IconButton
              size="small"
              onClick={onReadLaterToggle}
              sx={{
                color: isReadLater ? 'primary.main' : 'text.secondary',
                p: 0.5
              }}
            >
              <Bookmark size={18} />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => window.open(article.url, '_blank')}
              sx={{
                p: 0.5,
                color: 'text.secondary'
              }}
            >
              <ExternalLink size={18} />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                navigator.share({
                  title: article.title,
                  text: article.description,
                  url: article.url
                }).catch(console.error);
              }}
              sx={{
                p: 0.5,
                color: 'text.secondary'
              }}
            >
              <Share2 size={18} />
            </IconButton>
          </Box>
        </CardActionArea>

        {/* Expanded content with smooth animation */}
        <Collapse in={expanded} timeout={300}>
          <CardContent sx={{
            py: 1.5,
            px: 1,
            width: '100%'
          }}>
            {/* Summary */}
            <Typography
              variant="body2"
              color="text.primary"
              sx={{
                fontSize: '0.9rem',
                lineHeight: 1.6,
                mb: 2,
                width: '100%'
              }}
            >
              {article.summary}
            </Typography>

            {/* Main points */}
            {article.main_points && (
              <Box sx={{ mb: 2, width: '100%' }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: '0.85rem',
                    fontWeight: 600,
                    mb: 1,
                    color: theme.palette.text.primary
                  }}
                >
                  Main points:
                </Typography>
                <Box component="ul" sx={{
                  m: 0,
                  pl: 2,
                  width: '100%',
                  '& li': {
                    fontSize: '0.85rem',
                    color: theme.palette.text.secondary,
                    lineHeight: 1.6,
                    mb: 0.75,
                    '&::marker': {
                      color: theme.palette.primary.main
                    }
                  }
                }}>
                  {Array.isArray(article.main_points)
                    ? article.main_points.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))
                    : typeof article.main_points === 'string'
                      ? article.main_points.split('\n').map((point, index) => (
                        point.trim() && <li key={index}>{point.trim()}</li>
                      ))
                      : null
                  }
                </Box>
              </Box>
            )}

            {/* Takeaways */}
            {article.takeaways && (
              <Box sx={{ width: '100%' }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: '0.85rem',
                    fontWeight: 600,
                    mb: 1,
                    color: theme.palette.text.primary
                  }}
                >
                  Key takeaways:
                </Typography>
                <Box component="ul" sx={{
                  m: 0,
                  pl: 2,
                  width: '100%',
                  '& li': {
                    fontSize: '0.85rem',
                    color: theme.palette.text.secondary,
                    lineHeight: 1.6,
                    mb: 0.75,
                    '&::marker': {
                      color: theme.palette.primary.main
                    }
                  }
                }}>
                  {Array.isArray(article.takeaways)
                    ? article.takeaways.map((takeaway, index) => (
                      <li key={index}>{takeaway}</li>
                    ))
                    : typeof article.takeaways === 'string'
                      ? article.takeaways.split('\n').map((takeaway, index) => (
                        takeaway.trim() && <li key={index}>{takeaway.trim()}</li>
                      ))
                      : null
                  }
                </Box>
              </Box>
            )}
          </CardContent>
        </Collapse>
      </Card>
    </MobileSwipeableArticle>
  );
};

export default MobileArticleComponent;
