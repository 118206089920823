import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsyncList } from 'react-stately';
import { useAppSettings } from '../contexts/AppSettingsContext';
import ApiService from '../services/api';
import { useSnackbar } from '../contexts/SnackbarContext';
import {
  Box,
  Fade,
  useMediaQuery,
  Alert,
  Snackbar,
  Typography,
  Accordion,
  AccordionSummary,
  Skeleton,
  Divider,
  alpha,
  Button,
  Chip,
} from '@mui/material';
import { Sparkles, Zap, Clock } from 'lucide-react';
import { useTheme } from '@mui/material/styles';
import { getImportance } from '../variables/importanceOptions';
import ArticleComponent from './ArticleComponent';
import MobileArticleComponent from './Mobile/MobileArticleComponent';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmptyState from './EmptyState';
import EndOfArticles from './EndOfArticles';
import FloatingNav from './FLoatingNav';
import MobileNewsFeedSkeleton from './Mobile/MobileNewsFeedSkeleton';

// Composant de bannière de prévisualisation
// pour les utilisateurs non abonnés
const PreviewBanner = ({ previewMessage, onSubscribe }) => {
  return (
    <Alert
      severity="info"
      icon={<Sparkles size={24} />}
      sx={{
        mb: 3,
        borderRadius: 2,
        '& .MuiAlert-message': {
          width: '100%'
        },
        // Ajuster l'alignement et l'espacement des éléments de l'Alert
        '& .MuiAlert-action': {
          pt: 0,
          alignItems: 'center',
          marginRight: 0
        }
      }}
      action={
        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<Zap size={16} />}
          onClick={onSubscribe}
          sx={{
            borderRadius: 2,
            whiteSpace: 'nowrap',  // Empêche le texte de passer à la ligne
            minWidth: 'fit-content' // Assure que le bouton prend la largeur de son contenu
          }}
        >
          Start your free trial
        </Button>
      }
    >
      <Box>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          {previewMessage}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Clock size={16} />
            <Typography variant="caption">
              Viewing last month's articles
            </Typography>
          </Box>
          <Chip
            label="5 articles preview"
            size="small"
            color="primary"
            variant="outlined"
          />
        </Box>
      </Box>
    </Alert>
  );
};


// Composant de squelette pour les articles
const ArticleSkeleton = React.memo(() => {
  return (
    <Box
      sx={{
        p: 1,
        mb: 2,
        borderRadius: 1,
        backgroundColor: 'background.paper',
        boxShadow: 1,
        width: '100%',
        maxWidth: '100%'
      }}
    >
      {/* Header with dot and title */}
      <Box display="flex" alignItems="center" mb={1}>
        {/* Red dot status */}
        <Skeleton
          variant="circular"
          width={12}
          height={12}
          sx={{
            mr: 2,
            backgroundColor: 'grey',
            opacity: 0.3
          }}
        />
        {/* Title */}
        <Skeleton variant="text" width="60%" height={24} />
        {/* Timestamp */}
        <Box flexGrow={1} display="flex" justifyContent="flex-end">
          <Skeleton variant="text" width={120} />
        </Box>
      </Box>

      {/* Source link */}
      <Box display="flex" alignItems="center" ml={4} mb={1}>
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="50%" sx={{ ml: 1 }} />
      </Box>

      {/* Description text */}
      <Box ml={4} mb={2}>
        <Skeleton variant="text" width="95%" />
      </Box>

      <Divider />

      {/* Bottom action buttons and tags */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        {/* Action buttons */}
        <Box display="flex" gap={1}>
          {[1, 2, 3, 4].map((_, index) => (
            <Skeleton
              key={index}
              variant="circular"
              width={36}
              height={36}
            />
          ))}
        </Box>
        {/* Tags */}
        <Box display="flex" gap={1}>
          {[1, 2].map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              width={80}
              height={32}
              sx={{ borderRadius: 4 }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
});

ArticleSkeleton.displayName = 'ArticleSkeleton';



// ------------------------------------------------
// Composant principal pour la lecture des articles
// ------------------------------------------------
const NewsReader = React.memo(({ filters, onResetFilters }) => {
  // Contextes et services
  const { setUnreadArticlesCount } = useAppSettings();
  const api = ApiService();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // États locaux
  const [hasMore, setHasMore] = useState(true);
  const [expandedArticle, setExpandedArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // États pour la gestion des articles
  const [readStates, setReadStates] = useState({});
  const [readLaterStates, setReadLaterStates] = useState({});
  const [likeStates, setLikeStates] = useState({});
  const [removingArticles, setRemovingArticles] = useState(new Set());

  // État pour le mode de prévisualisation
  const [previewMode, setPreviewMode] = useState(false);
  const [previewMessage, setPreviewMessage] = useState('');

  // Refs pour éviter les doubles requêtes
  const pendingRequests = useMemo(() => ({
    read: new Set(),
    readLater: new Set(),
    like: new Set()
  }), []);


  // Fonction pour obtenir l'état de lecture d'un article
  const getReadState = useCallback((articleId, article) => {
    return readStates[articleId] ?? article?.read ?? false;
  }, [readStates]);

  // Ajouter une fonction pour obtenir le style de l'article
  const getArticleStyle = useCallback((isRead) => {
    return {
      opacity: isRead ? 0.7 : 1,
      backgroundColor: isRead ? alpha(theme.palette.background.paper, 0.5) : 'transparent',
      transition: 'opacity 0.3s ease, background-color 0.3s ease',
    };
  }, [theme]);

  // Fonction pour obtenir l'état "read later" d'un article
  const getReadLaterState = useCallback((articleId, article) => {
    return readLaterStates[articleId] ?? article?.read_later ?? false;
  }, [readLaterStates]);



  // Configuration de la liste asynchrone
  const list = useAsyncList({
    async load({ cursor }) {
      const params = {
        sort_order: filters.sortOrder || 'desc',
        max_results: 10,
        cursor,
        lang: filters.language
      };

      // Ajout des paramètres conditionnels
      if (filters.selectedProfile > 0) {
        params.profile_ids = filters.selectedProfile;
      }

      if (filters.searchTerm) {
        params.keyword_search = filters.searchTerm;
      }

      if (filters.timeRange && filters.timeRange !== 'all') {
        params.days = filters.timeRange;
      }

      if (filters.selectedView === 'all') {
        params.include_read = true;
      } else if (filters.selectedView === 'saved') {
        params.include_read_later = true;
        //delete params.profile_ids;
      }

      try {
        setIsLoading(true);
        const response = await api.get('/articles', params);

        // Gérer le mode preview pour les utilisateurs non abonnés
        if (response.data.preview_mode) {
          setPreviewMode(true);
          setPreviewMessage(response.data.preview_message);
        } else {
          setPreviewMode(false);
        }

        setHasMore(response.data.has_more);
        return {
          items: response.data.articles,
          cursor: response.data.cursor
        };
      } catch (error) {
        console.error('Error fetching articles:', error);
        showSnackbar('Error loading articles', 'error');
        return { items: [], cursor: null };
      } finally {
        setIsLoading(false);
      }
    }
  });


  const handleSubscribe = () => {
    navigate('/settings?tab=subscription');
    //window.location.href = `/settings?tab=subscription&from=${encodeURIComponent(window.location.pathname)}`;
  };

  // Fonction pour obtenir la couleur de la carte en fonction du score
  const getImportanceColor = useCallback((score) => {
    const importance = getImportance(score);
    switch (importance) {
      case 'critical':
      case 'high':
        return theme.palette.error.main;
      case 'medium':
        return theme.palette.warning.main;
      default:
        return theme.palette.success.main;
    }
  }, [theme]);


  // Handler pour basculer l'état de lecture d'un article
  const toggleArticleRead = useCallback(async (articleId) => {
    // Vérifier si une requête est déjà en cours pour cet article
    if (pendingRequests.read.has(articleId)) return;
    pendingRequests.read.add(articleId);

    // Obtenir l'état actuel
    const currentReadState = readStates[articleId] ??
      list.items.find(a => a.article_id === articleId)?.read ?? false;

    try {
      if (currentReadState) {
        // Marquer comme non-lu
        await api.delete(`/articles/read/${articleId}`);
        setReadStates(prev => ({ ...prev, [articleId]: false }));
        setUnreadArticlesCount(prev => prev + 1);
      } else {
        // Marquer comme lu
        await api.post(`/articles/read/${articleId}`);
        setReadStates(prev => ({ ...prev, [articleId]: true }));
        setUnreadArticlesCount(prev => Math.max(0, prev - 1));
      }
    } catch (error) {
      console.error('Error updating article read status:', error);
      showSnackbar('Error updating article status', 'error');
    } finally {
      pendingRequests.read.delete(articleId);
    }
  }, [api, list.items, readStates, pendingRequests.read, setUnreadArticlesCount, showSnackbar]);


  // Handler pour basculer l'état "à lire plus tard"
  const toggleReadLater = useCallback(async (articleId) => {
    if (pendingRequests.readLater.has(articleId)) return;
    pendingRequests.readLater.add(articleId);

    // Trouver l'article et son état actuel
    const article = list.items.find(a => a.article_id === articleId);
    const currentState = getReadLaterState(articleId, article);
    const newState = !currentState;

    try {
      const endpoint = `/articles/readlater/${articleId}`;
      const method = newState ? 'post' : 'delete';
      await api[method](endpoint);

      setReadLaterStates(prev => ({ ...prev, [articleId]: newState }));

      // Mise à jour de la liste si nécessaire
      const articleIndex = list.items.findIndex(a => a.article_id === articleId);
      if (articleIndex !== -1) {
        list.update(articleIndex, {
          ...list.items[articleIndex],
          read_later: newState
        });
      }
    } catch (error) {
      console.error('Error updating read later status:', error);
      showSnackbar('Error updating save status', 'error');
    } finally {
      pendingRequests.readLater.delete(articleId);
    }
  }, [api, list, getReadLaterState, pendingRequests.readLater, showSnackbar]);

  // Handler pour basculer l'état "j'aime"
  const toggleLike = useCallback(async (articleId) => {
    if (pendingRequests.like.has(articleId)) return;
    pendingRequests.like.add(articleId);

    const currentState = likeStates[articleId] ??
      list.items.find(a => a.article_id === articleId)?.liked ?? false;
    const newState = !currentState;

    try {
      const endpoint = `/articles/like/${articleId}`;
      const method = newState ? 'post' : 'delete';
      await api[method](endpoint);

      setLikeStates(prev => ({ ...prev, [articleId]: newState }));

      // Mise à jour de la liste si nécessaire
      const articleIndex = list.items.findIndex(a => a.article_id === articleId);
      if (articleIndex !== -1) {
        list.update(articleIndex, {
          ...list.items[articleIndex],
          liked: newState
        });
      }
    } catch (error) {
      console.error('Error updating like status:', error);
      showSnackbar('Error updating like status', 'error');
    } finally {
      pendingRequests.like.delete(articleId);
    }
  }, [api, list, likeStates, pendingRequests.like]);

  // Effet pour recharger la liste quand les filtres changent
  useEffect(() => {
    list.reload();
  }, [filters]);

  const markArticleAsRead = async (articleId) => {
    try {
      // Vérifier si une requête est déjà en cours pour cet article
      if (pendingRequests.read.has(articleId)) return;
      pendingRequests.read.add(articleId);

      // Appel à l'API pour marquer l'article comme lu
      await api.post(`/articles/read/${articleId}`);
      
      // Mise à jour locale de l'état
      setReadStates(prev => ({ ...prev, [articleId]: true }));
      setUnreadArticlesCount(prev => Math.max(0, prev - 1));

      showSnackbar('Article marked as read', 'success');
    } catch (error) {
      console.error('Error marking article as read:', error);
      showSnackbar('Error marking article as read', 'error');
    } finally {
      pendingRequests.read.delete(articleId);
    }
  };

  const handleMarkAsRead = async (articleId) => {
    await markArticleAsRead(articleId);
  };

  // Rendu conditionnel pour les états vides et le chargement
  if (isLoading && list.items.length === 0) {
    return (
      isMobile ? (
        <MobileNewsFeedSkeleton />
      ) : (
        <Fade in={true} timeout={500}>
          <Box sx={{ mt: 3 }}>
            {[...Array(5)].map((_, index) => (
              <ArticleSkeleton key={index} />
            ))}
          </Box>
        </Fade>
      )
    );
  }

  // Si le chargement est terminé et qu'il n'y a pas d'articles
  if (!isLoading && list.items.length === 0 && !list.isLoading) {
    return (
      <EmptyState
        message={filters.searchTerm
          ? "Try adjusting your search terms or filters to find more articles."
          : "No articles match your current criteria. Try adjusting your filters or check back later."}
        onReset={onResetFilters}
        filters={filters}
      />
    );
  }

  return (
    <>
      {previewMode && (
        <Box sx={{ px: { xs: 2, sm: 0 } }}> {/* Ajout de padding sur mobile */}
          <PreviewBanner
            previewMessage={previewMessage}
            onSubscribe={handleSubscribe}
          />
        </Box>
      )}

      <InfiniteScroll
        dataLength={list.items.length}
        next={() => list.loadMore()}
        hasMore={hasMore && !previewMode} // Désactiver le chargement infini en mode preview
        loader={<ArticleSkeleton />}
        endMessage={<EndOfArticles filters={filters} />}
        style={{
          overflow: 'visible' // Empêche le scroll horizontal
        }}
      >
        <Box sx={{
          mt: 3,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center', // Centre les articles
          px: { xs: 2, sm: 0 } // Padding sur mobile
        }}>
          {list.items.map(article => {
            const isRead = getReadState(article.article_id, article);
            const isReadLater = getReadLaterState(article.article_id, article);
            const articleStyle = getArticleStyle(isRead);

            return isMobile ? (
              <MobileArticleComponent
                key={article.article_id}
                article={article}
                onReadLaterToggle={() => toggleReadLater(article.article_id)}
                onLikeToggle={() => toggleLike(article.article_id)}
                isReadLater={isReadLater}
                isLiked={likeStates[article.article_id]}
                cardColor={getImportanceColor(article.score)}
                onReadToggle={() => toggleArticleRead(article.article_id)}
                isRead={getReadState(article.article_id, article)}
                onMarkAsRead={handleMarkAsRead}
                sx={{
                  ...articleStyle,
                  width: '100% !important', // Force la largeur à 100%
                  maxWidth: '100% !important', // Empêche le dépassement
                  mx: 0 // Supprime les marges horizontales
                }}
              />
            ) : (
              <Box sx={{
                width: '100%',
                maxWidth: '2000px', // Largeur fixe pour desktop
                mx: 'auto' // Centre les articles
              }}>
                <ArticleComponent
                  key={article.article_id}
                  article={article}
                  isLiked={likeStates[article.article_id]}
                  onLikeToggle={() => toggleLike(article.article_id)}
                  isRead={isRead}
                  onReadToggle={() => toggleArticleRead(article.article_id)}
                  isReadLater={isReadLater}
                  onReadLaterToggle={() => toggleReadLater(article.article_id)}
                  expanded={expandedArticle === article.article_id}
                  onExpandChange={() => setExpandedArticle(
                    expandedArticle === article.article_id ? null : article.article_id
                  )}
                  cardColor={getImportanceColor(article.score)}
                  filters={filters}
                  language={filters.language}
                  sx={(theme) => ({
                    ...articleStyle,
                    // Fusionner avec d'autres styles si nécessaire
                  })}
                />
              </Box>
            );
          })}

        </Box>
      </InfiniteScroll>
      {/* Si en version desktop, afficher la barre de navigation flottante */}
      {!isMobile && <FloatingNav />}
    </>
  );
});

NewsReader.displayName = 'NewsReader';

export default NewsReader;